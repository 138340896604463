<template>
  <div class="space-y-10">
    <article v-if="canCreateTicketAndEvent || canCreateCashDeclarationForm">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          v-if="canCreateTicketAndEvent"
          variant="primary"
          outline
          rounded="none"
          @click="createEvent"
          icon="add"
          text="Provision Tickets"
        >
        </asom-button>
        <asom-button
          v-if="canCreateTicketAndEvent"
          variant="primary"
          outline
          rounded="none"
          @click="createTicket"
          icon="tag"
          text="Issue Tickets"
        >
        </asom-button>
        <asom-button
          v-if="canCreateCashDeclarationForm"
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('/cash-mgnt/cash-declaration-form/create')"
          icon="duplicate"
          text="Declare Cash Bag"
        >
        </asom-button>
      </section>
    </article>
    <asom-alert
      class="col-span-2"
      v-if="error"
      variant="error"
      :error-message="error"
    />
    <div v-if="!isLoading" class="space-y-4">
      <asom-card title="Events">
        <div class="space-y-8">
          <div v-for="event in events" :key="event.eventId">
            <div class="flex items-center pb-4 gap-2">
              <div class="text-xl font-semibold">{{ event.eventName }}</div>
              <asom-badge
                v-if="event.isCurrent && !event.isClosed"
                variant="success"
                >Current Event
              </asom-badge>
              <asom-badge v-else>{{ event.startDate }}</asom-badge>
              <asom-badge v-if="event.isClosed" variant="warning"
                >Closed Event
              </asom-badge>
              <asom-button
                v-else-if="canCreateTicketAndEvent"
                size="xs"
                outline
                text="Close Event"
                variant="error"
                @click="closeEventClicked(event)"
              />
            </div>
            <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
              <div
                class="relative rounded-lg bg-white px-3 py-2 shadow flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <div class="flex-shrink-0">
                  <div class="p-2 h-10 w-10 bg-button rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex-1 min-w-0">
                  <a class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium text-gray-500">
                      Tickets Provisioned
                    </p>
                    <p class="text-3xl font-semibold text-gray-900 truncate">
                      {{ event.noOfTickets }}
                    </p>
                  </a>
                </div>
              </div>
              <div
                class="relative rounded-lg bg-white px-3 py-2 shadow flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <div class="flex-shrink-0">
                  <div class="p-2 h-10 w-10 bg-button rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex-1 min-w-0">
                  <a class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium text-gray-500">
                      Tickets Available
                    </p>
                    <p class="text-3xl font-semibold text-gray-900 truncate">
                      {{ event.ticketAvailable }}
                    </p>
                  </a>
                </div>
              </div>
              <div
                class="relative rounded-lg bg-white px-3 py-2 shadow flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <div class="flex-shrink-0">
                  <div class="p-2 h-10 w-10 bg-button rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex-1 min-w-0">
                  <a class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium text-gray-500">
                      Value of Sold Tickets
                    </p>
                    <p class="text-3xl font-semibold text-gray-900 truncate">
                      {{ formatCurrency(event.valueOfSoldTickets) }}
                    </p>
                  </a>
                </div>
              </div>
              <div
                class="relative rounded-lg bg-white px-3 py-2 shadow flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <div class="flex-shrink-0">
                  <div class="p-2 h-10 w-10 bg-button rounded-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                </div>
                <div class="flex-1 min-w-0">
                  <a class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <p class="text-sm font-medium text-gray-500">
                      Value of Unsold Tickets
                    </p>
                    <p class="text-3xl font-semibold text-gray-900 truncate">
                      {{ formatCurrency(event.valueOfSparedTickets) }}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </asom-card>

      <asom-card title="Pre-encoded Ticket Log">
        <asom-client-table
          :columns="[
            'transactionNo',
            'event',
            'dateCreated',
            'transactionType',
            'noOfTickets',
            'ticketValue',
            'action',
          ]"
          :data="getTableData"
          :sortableColumns="[
            'transactionNo',
            'event',
            'dateCreated',
            'transactionType',
            'noOfTickets',
            'ticketValue',
          ]"
          :searchableDateColumns="['dateCreated']"
          :searchableCurrencyColumns="['ticketValue']"
        >
          <template v-slot:header_transactionNo>Transaction No</template>
          <template v-slot:transactionNo="scopedSlots">
            <div
              :class="[
                'space-x-2 flex flex-row items-center',
                scopedSlots.rowData.isDeleted && 'line-through',
              ]"
            >
              <span class="flex-1">{{
                scopedSlots.rowData.transactionNo
              }}</span>
              <asom-tooltip
                v-if="get(scopedSlots.rowData, 'addedToCDF')"
                tooltip-text="Bagged in Cash Declaration"
              >
                <template #toggleText>
                  <asom-badge variant="primary" class="p-1">
                    <asom-icon icon="shopping-bag" size="sm" />
                  </asom-badge>
                </template>
              </asom-tooltip>
              <asom-tooltip
                v-if="get(scopedSlots.rowData, 'addedToShiftEnd')"
                tooltip-text="Added to shift End"
              >
                <template #toggleText>
                  <asom-badge variant="success" class="p-1">
                    <asom-icon icon="mail" size="sm" />
                  </asom-badge>
                </template>
              </asom-tooltip>
            </div>
          </template>
          <template v-slot:event="scopedSlots">
            <span>{{ scopedSlots.data.eventName }}</span>
          </template>
          <template v-slot:header_dateCreated>Date</template>
          <template v-slot:header_transactionType>Transaction Type</template>
          <template v-slot:header_noOfTickets>No of Tickets</template>
          <template v-slot:header_ticketValue>Ticket Value</template>
          <template v-slot:ticketValue="scopedSlots">
            <span>{{ formatCurrency(scopedSlots.data) }}</span>
          </template>
          <template v-slot:dateCreated="scopedSlots">
            <span>{{ displayUtcDate(scopedSlots.data) }}</span>
          </template>
          <template v-slot:action="scopedSlots">
            <div
              class="flex flex-col gap-1 w-48"
              v-if="showButton(scopedSlots.rowData)"
            >
              <asom-button
                size="sm"
                outline
                text="Cancel Entry"
                variant="error"
                @click="voidTransaction(scopedSlots.rowData)"
              />
              <asom-button
                v-if="isTransactionEditable(scopedSlots.rowData)"
                size="sm"
                outline
                text="Update"
                @click="navigateToUpdate(scopedSlots.rowData)"
              />
            </div>
          </template>
        </asom-client-table>
      </asom-card>
      <activity-log-list
        title="Pre-encoded Ticket Transaction Activity Log"
        :data="activityLogs"
      >
        <template v-slot:field_noOfTickets>No. of Tickets</template>
      </activity-log-list>
    </div>
    <asom-modal title="Close Event" v-model="showModal" :dismissible="false">
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse">
        <div class="pl-4">
          <asom-button @click="onCloseEvent" text="OK" />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-modal title="Void Log" v-model="voidAlert" :dismissible="false">
      <p class="pt-4" v-if="isProvisionedTransaction(selectedTransaction)">
        This would void the event '{{
          get(selectedTransaction, "event.eventName")
        }}' along with all existing pre-encoded ticket logs of it. Do you want
        to continue?
      </p>
      <p class="pt-4" v-else>
        Are you sure you want to continue?
      </p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button @click="onVoid(selectedTransaction)" text="OK" />
        </div>
        <div>
          <asom-button
            @click="toggleVoidAlert(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  editTicketLog,
  closeEvent,
  getPreEncodedMainPageData,
  createPsmLog,
} from "../../../../services/cashManagement.service";
import ActivityLogList from "../../_ActivityLogList.vue";
import { formatCurrency } from "../../../../helpers/numbers";
import { psc } from "../../../../constants/APIEnums/cashManagementEnums";

export default {
  name: "PreEncodedTicketMainPage",
  components: {
    ActivityLogList,
  },
  data() {
    return {
      isLoading: false,
      events: [],
      showModal: false,
      selectedEventToClose: null,
      ticketLogs: [],
      activityLogs: [],
      voidAlert: false,
      selectedTransaction: null,
      error: null,
      transactionTypes: psc.preEncodedTicket.TRANSACTION_TYPE,
    };
  },
  mounted() {
    this.getPageData();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getPageData();
    },
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      canCreateTicketAndEvent: 'auth/canCreateTicketAndEvent',
      canCreateCashDeclarationForm: 'auth/canCreateCashDeclarationForm',
    }),
    getTableData() {
      return this.ticketLogs.map((row) => {
        if (row.isDeleted) row.deleted = true;
        return row;
      });
    },
  },
  methods: {
    get,
    formatCurrency,
    showButton({ isDeleted, addedToCDF, addedToShiftEnd }) {
      return this.canCreateTicketAndEvent && !isDeleted && !addedToCDF && !addedToShiftEnd;
    },
    isTransactionEditable(transaction) {
      const isEditableType =
        this.showButton(transaction) &&
        get(transaction, "transactionTypeEnum") !== 3;
      const isActiveEvent = this.events.some(
        (_e) => _e.eventId == transaction.eventId && !_e.isClosed
      );
      return isEditableType && isActiveEvent && this.canCreateTicketAndEvent;
    },
    isProvisionedTransaction(transaction) {
      return (
        get(transaction, "transactionType") ==
        psc.preEncodedTicket.TRANSACTION_TYPE.PROVISION.NAME
      );
    },
    displayUtcDate,
    async getPageData() {
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const resp = await getPreEncodedMainPageData({
        pSCId: this.pscId,
      });
      if (resp.success) {
        this.ticketLogs = get(resp.payload.data, "transactions", []);
        let _activityLogs = get(resp.payload.data, "activityLogs", []);
        const fields = ['noOfTickets'];
        _activityLogs.forEach( log => {
          let result = [];
          var obj = get(log, "fieldChanges", {});
          if(obj){
            fields.forEach( field => {
              if(obj[field] || obj[field + 'Updated']){
                let temp =
                { fieldName: field,
                  values: {
                    oldValue: obj[field],
                    newValue: obj[field + 'Updated']
                  }
                }
                result.push(temp);
              }
            })
          }
          log.fieldChanges = result;
        })
        this.activityLogs = _activityLogs;
        this.events = get(resp.payload.data, "events", []);
        this.isLoading = false;
        this.error = null;
      } else {
        this.error = resp.payload;
        this.$scrollTop();
        return;
      }
    },
    closeEventClicked(event) {
      this.selectedEventToClose = event;
      this.showModal = true;
    },
    onCloseEvent() {
      this.closeEventConfirm(this.selectedEventToClose);
    },
    onCloseModal() {
      this.showModal = false;
    },
    navigateToUpdate({ transactionId }) {
      this.$router.push({
        name: "Pre Encoded Update Ticket",
        params: { id: transactionId },
      });
    },
    toggleVoidAlert(value) {
      this.voidAlert = value;
    },
    voidTransaction(selectedTransaction) {
      this.selectedTransaction = selectedTransaction;
      this.toggleVoidAlert(true);
    },
    createEvent() {
      this.$router.push({ name: "Pre Encoded Ticket Create Event" });
    },
    createTicket() {
      this.$router.push({ name: "Pre Encoded Create Ticket" });
    },
    shiftEndSummary() {
      this.$router.push({ name: "PET Shift End Summary" });
    },
    async onVoid(transaction) {
      this.toggleVoidAlert(false);
      const resp = await editTicketLog({
        id: transaction.transactionId,
        noOfTickets: transaction.noOfTickets,
        remarks: "void transaction",
        isVoided: true,
      });
      if (resp.success) {
        this.error = null;
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.getPageData();
    },
    async closeEventConfirm(event) {
      const resp = await this.closeEvent(event);
      if (resp.success) {
        this.error = null;
        this.getPageData();
      } else {
        this.error = resp.payload;
        this.$scrollTop();
      }
      this.showModal = false;
    },
    async closeEvent(event) {
      const response = await closeEvent({
        PSCId: this.pscId,
        eventId: get(event, "eventId", ""),
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      return response;
    },
    async createManualRecord(event) {
      const response = await createPsmLog({
        pscId: this.pscId,
        inputType: 6,
        input: "Pre-encoded Ticket Event",
        description: get(event, "eventName", ""),
        waived: get(event, "valueOfSparedTickets", null),
        payment: 0,
        receipt: 0,
        attachmentIds: [],
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      return response;
    },
  },
};
</script>
